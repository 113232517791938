import Container from "./Container";
import TextLink from "./TextLink";

export default function Footer() {
  return (
    <div className="px-4 py-8 text-sm text-stone-500 bg-stone-100">
      <Container>
        <div className="md:flex-between flex-col md:flex-row space-y-2 md:space-y-0">
          <div>
            &copy; DeeYa.
            <span className="inline-block md:hidden">All rights reserved.</span>
          </div>
          <div className="flex space-x-4">
            <TextLink url="/terms">이용약관</TextLink>
            <TextLink url="/privacy">개인정보처리방침</TextLink>
          </div>
        </div>
      </Container>
    </div>
  );
}
