import classNames from "classnames";
import Link from "next/link";

function PagePrev() {
  return (
    <Link href="/">
      <div className="hover:underline text-indigo-500 px-2 h-8 flex-center">
        Prev
      </div>
    </Link>
  );
}

function PageNext() {
  return (
    <Link href="/">
      <div className=" hover:underline text-indigo-500 px-2 h-8 flex-center">
        Next
      </div>
    </Link>
  );
}

function PageItem({ page, isActive }) {
  return (
    <Link href="/">
      <div
        className={classNames(
          "hover:bg-stone-200 rounded-full shrink-0 h-8 w-8 text-sm  flex-center",
          {
            "bg-indigo-500 text-white font-semibold hover:bg-indigo-600 hover:text-white":
              isActive,
          }
        )}
      >
        <span>{page}</span>
      </div>
    </Link>
  );
}

export default function PageNavigation() {
  const nowPage = 3;
  return (
    <div className="justify-center flex space-x-1 px-4 pt-8 py-4 ">
      <PagePrev page={nowPage - 1} />
      <PageItem page={1} />
      <PageItem page={2} isActive={true} />
      <PageItem page={3} />
      <PageItem page={4} />
      <PageNext page={nowPage + 1} />
    </div>
  );
}
