import Layout from "@/components/blog/Layout";
import PageTitle from "@/components/blog/PageTitle";
import RecentPosts from "@/components/blog/RecentPosts";
import PageNavigation from "@/components/blog/PageNavigation";
import { strcut } from "@/utils/str";
import Link from "next/link";

const categories = {
  finance: {
    title: "금융",
    subtitle: "세상 돌아가는 소식부터 내게 꼭 필요한 금융 정보를 알려드립니다.",
  },
  welfare: {
    title: "복지",
    subtitle: "알면 도움되는 여러가지 복지 정책을 소개합니다.",
  },
  loan: {
    title: "대출",
    subtitle: "다양한 대출 상품 및 관련 정보를 소개합니다.",
  },
  health: {
    title: "건강",
    subtitle: "건강을 위한 유용한 팁, 습관, 의학정보를 알려드려요.",
  },
  lifestyle: {
    title: "라이프스타일",
    subtitle: "세상 살아가는 이야기 및 다양한 일상꿀팁을 소개합니다.",
  },
};

const Header = ({ slug }) => {
  const cate = categories[slug];
  const { title, subtitle } = cate || {};

  if (!slug) return null;
  return (
    <PageTitle
      title={(slug.startsWith("#") && slug.replace("#", "")) || title}
      subtitle={subtitle}
    />
    // <div className="border-l-4 pl-8 border-indigo-500 mb-8">
    //   <div className="text-3xl">
    //     {(slug.startsWith("#") && slug.replace("#", "")) || title}
    //   </div>
    //   {subtitle && (
    //     <div className="text-sm text-stone-500 mt-2">{subtitle}</div>
    //   )}
    // </div>
  );
};

function PostCard() {
  return (
    <Link href="/1">
      <div className="border-b md:mb-4 md:pb-4">
        <div className="p-4 md:rounded-lg hover:bg-indigo-50 flex flex-col space-y-4">
          <div className="font-semibold text-xl md:text-2xl">
            2024 개인사업자 종합소득세 비용처리 완벽 정리
          </div>
          <div className="text-stone-600 text-sm md:text-base">
            {strcut(
              `복식부기란? 복식부기는 왜 써야 하고, 누가 써야 하는 걸까요? 전문가에게
        세무를 맡기더라도, 사업자라면 복식부기에 대한 기초 지식은 있어야 합니다.
        비전문가도 쉽게 이해할 수 있도록 복식부기에 관한 3W(What, Why, Who)를
        쉽게 알려드립니다.`,
              150,
              "..."
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default function BlogArchive({ slug }) {
  return (
    <Layout sidebar={<RecentPosts />}>
      <div className="flex flex-col py-4 md:py-16">
        <Header slug={slug} />
        <PostCard />
        <PostCard />
        <PostCard />
        <PostCard />
        <PageNavigation />
      </div>
    </Layout>
  );
}
