export default function PageTitle({ title, subtitle }) {
  return (
    <div className="border-l-4 pl-3 md:pl-8 border-indigo-500 mb-4 md:mb-8">
      <div className="text-xl md:text-3xl">{title}</div>
      {subtitle && (
        <div className="text-sm text-stone-500 mt-1 md:mt-2">{subtitle}</div>
      )}
    </div>
  );
}
