import Link from "next/link";
import Container from "./Container";
import { useState } from "react";

function Logo() {
  return (
    <Link href="/">
      <div className="font-extrabold text-2xl text-indigo-500">Daisy</div>
    </Link>
  );
}
function MobileMenuItem({ slug, children }) {
  return (
    <Link href={`/${slug}`}>
      <div className="px-4 py-2 hover:text-indigo-500 hover:font-bold hover:bg-stone-100">
        {children}
      </div>
    </Link>
  );
}

function MobileMenu({ menuItems }) {
  return (
    <div className="fixed top-[61px] left-0 right-0 bottom-0 bg-white py-4">
      {menuItems.map((item, i) => (
        <MobileMenuItem key={`layout-mobile-menu-item-${i}`} slug={item.slug}>
          {item.title}
        </MobileMenuItem>
      ))}
    </div>
  );
}

function MenuItem({ slug, children }) {
  return (
    <Link href={`/${slug}`}>
      <div className="hover:text-indigo-500">{children}</div>
    </Link>
  );
}

function Menu({ menuItems }) {
  return (
    <div className="flex space-x-8 mr-8 font-bold">
      {menuItems.map((item, i) => (
        <MenuItem key={`layout-menu-item-${i}`} slug={item.slug}>
          {item.title}
        </MenuItem>
      ))}
    </div>
  );
}

function MobileMenuButton({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer hover:bg-stone-200 rounded-lg h-[40px] w-[40px] flex-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
        />
      </svg>
    </div>
  );
}

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    {
      slug: "finance",
      title: "금융",
    },
    {
      slug: "finance",
      title: "복지",
    },
    {
      slug: "loan",
      title: "대출",
    },
    {
      slug: "health",
      title: "건강",
    },
    {
      slug: "lifestyle",
      title: "라이프스타일",
    },
  ];

  return (
    <div className="border-b sticky top-0 z-10 bg-white">
      <Container>
        <div className="flex  px-4 h-[60px] items-center flex-between">
          <Logo />
          <div className="hidden md:block">
            <Menu menuItems={menuItems} />
          </div>
          <div className="block md:hidden">
            <MobileMenuButton onClick={() => setIsOpen(!isOpen)} />
            {isOpen && <MobileMenu menuItems={menuItems} />}
          </div>
        </div>
      </Container>
    </div>
  );
}
