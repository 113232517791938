import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Container from "./Container";

export default function Layout({ children, sidebar }) {
  return (
    <div className="min-h-screen w-full flex flex-col">
      <Header />
      <div className="flex-grow">
        <Container>
          <div className="flex flex-col md:flex-row md:space-x-32">
            <div className="flex-grow">{children}</div>
            {sidebar && <Sidebar>{sidebar}</Sidebar>}
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
