/* 문자열에서 모든 쉼표를 제거 */
export const strWithoutCommas = (str) => {
  return str.replace(/,/g, "");
};

/* 문자열에서 모든 공백을 제거 */
export const strWithoutSpaces = (str) => {
  return str.replace(/ /g, "");
};

/* 대괄호 안의 문자열만 추출 */
export const strInBrackets = (str) => {
  const matches = str.match(/\[([^\]]+)\]/g);
  if (!matches) return "";
  // 결과에서 대괄호를 제거하고, 문자열로 결합하여 반환
  return matches.map((match) => match.slice(1, -1)).join(" ");
};

/* 대괄호 및 대괄호 안의 문자열 제거 */
export const strRemoveBracketsAndContent = (str) => {
  // 정규 표현식을 사용하여 대괄호와 그 안의 내용을 모두 제거
  return str.replace(/\[.*?\]/g, "");
};

/* 글자수 줄이고 말줄임표 붙이기 */
export const strcut = (str, limit) => {
  return str ? (str.length < limit ? str : str.slice(0, limit) + "...") : str;
};

/* 문자열 끝에 +나 -가 붙어 있다면 제거 */
export const strRemoveTrailingPlusMinus = (str) => {
  return str.replace(/[\+\-]$/, "");
};

/* 첫글자를 대문자로 */
export const capitalize = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : null;
};

/* 첫글자가 $이면 true 아니면 false */
export const isDollar = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return false;
  }
  return str.charAt(0) === "$";
};

/* html 이스케이핑 */
export const escapeHtml = (html) => {
  return html
    .replaceAll(/</g, "&lt;")
    .replaceAll(/>/g, "&gt;")
    .replaceAll(/\s/g, (match) => (match === " " ? "&nbsp;" : match));
};

/* 공백을 대시로 바꾸기 */
export const replaceSpacesWithDash = (str) => {
  return str.replace(/\s+/g, "-");
};

/* 카멜케이스로 바꾸기 */
export function strToCamelCase(snakeStr) {
  return snakeStr.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
}
