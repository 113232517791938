import TextLink from "./TextLink";

export default function RecentPosts() {
  return (
    <div className="pb-8">
      <div className="font-bold text-stone-500 mb-4 text-base md:text-sm">
        최신글
      </div>
      <div className="flex flex-col space-y-2 text-indigo-500 text-base md:text-sm">
        <TextLink url="/">
          2024 개인사업자 종합소득세 비용처리 완벽정리
        </TextLink>
        <TextLink url="/">2024 개인사업자 종합소득세 계산법 및 구간</TextLink>
        <TextLink url="/">부동산 양도소득세 절세하는 방법 7가지</TextLink>
        <TextLink url="/">글로벌 최저한세 적용 대상, 규정, 최저한세율</TextLink>
        <TextLink url="/">종소세 계산기 바로가기</TextLink>
      </div>
    </div>
  );
}
